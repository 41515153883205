import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import {Layout} from '../components/index';
import {htmlToReact, withPrefix} from '../utils';
import { Helmet } from 'react-helmet';

function SEO(props) {
  const seo = props.page && props.page.seo;
  if (!seo) {
      return null;
  }
  return (
      <Helmet>
          {seo.title && <title>{ seo.title }</title>}
          {seo.description && <meta name="description" content={ seo.description } />}
          {seo.robots && <meta name="robots" content={ seo.robots.join(',') } />}
          {seo.extra && seo.extra.map((meta, index) => {
              const keyName = meta.keyName || 'name';
              const value = (meta.relativeUrl ? props.site.domain : '') + meta.value;
              return <meta key={index} {...{[keyName]: meta.name}} content={value} />
          })}
      </Helmet>
  );
}


// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Page extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
            <div className="outer">
              <div className="inner-medium">
                <article className="post post-full">
                  <header className="post-header">
                    <h1 className="post-title">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                    {_.get(this.props, 'pageContext.frontmatter.subtitle', null) && (
                    <div className="post-subtitle">
                      {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle', null))}
                    </div>
                    )}
                  </header>
                  {_.get(this.props, 'pageContext.frontmatter.image', null) && (
                  <div className="post-image">
                    <img src={withPrefix(_.get(this.props, 'pageContext.frontmatter.image', null))} alt={_.get(this.props, 'pageContext.frontmatter.image_alt', null)} />
                  </div>
                  )}
                  <div className="post-content">
                    {htmlToReact(_.get(this.props, 'pageContext.html', null))}
                  </div>
                </article>
              </div>
            </div>
            </Layout>
        );
    }
}
